// 玻璃切割机每个key的含义
export const glassEquipFormTextObj = {
  glass_model: '加工文件类型',
  contour_size_recoup: '板材尺寸补偿',
  cut_buffer_distance: '板件切割预留间距',
  use_category_folder: '根据玻璃颜色、材质、厚度归为不同文件夹',
}

export const glassEquipFormValueObj = {
  use_category_folder: {
    0: '关闭',
    1: '开启',
  },
}
