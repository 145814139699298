<template>
  <div class="container">
    <div class="group">
      <span class="head_title"></span>
      <div class="group_context">
        <div class="col">
          <span class="col_text"> 加工文件类型： </span>
          <el-select
            v-model="formData.glass_model"
            placeholder="请选择"
            size="mini"
            id="glass_setting_glass_model_select"
          >
            <el-option
              v-for="(item, index) in processFileTypeList"
              :key="item.glass_model"
              :label="item.modelName"
              :value="item.glass_model"
              :id="`glass_setting_glass_model_option_${index}`"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col">
          <span class="col_text"> 板材尺寸补偿： </span>
          <el-input
            size="mini"
            v-model="formData.contour_size_recoup"
            @input="
              handleInputVal($event, formData, 'contour_size_recoup', true)
            "
            id="glass_setting_sizeRecoup_input"
          ></el-input>
          mm
        </div>
        <div class="col">
          <span class="col_text"> 板件切割预留间距： </span>
          <el-input
            size="mini"
            v-model="formData.cut_buffer_distance"
            @input="
              handleInputVal($event, formData, 'cut_buffer_distance', false)
            "
            id="glass_setting_cut_buffer_distance_input"
          ></el-input>
          mm
        </div>
        <div class="col">
          <span class="col_text">
            根据玻璃颜色、材质、厚度归为不同文件夹：
          </span>
          <el-checkbox
            v-model="formData.use_category_folder"
            id="glass_setting_use_category_folder_checkbox"
          ></el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { regReplaceGlassEquipHandleInputVal } from '@/util/regReplace'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      processFileTypeList: [],
      value: '',
      formData: {
        glass_model: '',
        contour_size_recoup: 0,
        cut_buffer_distance: 1,
        use_category_folder: false,
      },
      oriFormData: {},
    }
  },
  computed: {
    ...mapState('equipEnvironment', ['previewInfo']),
    glassEquipData() {
      return JSON.parse(
        JSON.stringify(this.$store.state.equipSetting.glassEquipData)
      )
    },
  },
  watch: {
    '$store.state.equipSetting.glassEquipData': {
      // 先获取配置信息，再请求文件列表信息
      handler() {
        this.formData = JSON.parse(
          JSON.stringify(this.$store.state.equipSetting.glassEquipData)
        )
        this.formData.use_category_folder = Boolean(
          this.formData.use_category_folder
        )

        // 只有不是从后台通过应用进入的时候才会设置上初始值
        if (!this.$store.state.equipSetting.isApply) {
          this.oriFormData = JSON.parse(JSON.stringify(this.formData))
          this.oriFormData.use_category_folder = Number(
            this.oriFormData.use_category_folder
          )
        }
        this.getGlassModelList()
      },
    },
  },
  methods: {
    //  返回组件内部的对象
    getDealSetting() {
      const obj = JSON.parse(JSON.stringify(this.formData))
      obj.use_category_folder = Number(obj.use_category_folder)
      return {
        ...this.changeObjValType(obj),
      }
    },
    // 获取文件类型下拉列表
    getGlassModelList() {
      this.$get('/glass_list', { from: 'user' }, (res) => {
        if (res.status === 1) {
          if (!this.formData.glass_model) {
            this.formData.glass_model = res.data.model_list[0].glass_model
          }
          this.processFileTypeList = res.data.model_list
        } else {
          this.$message({
            type: 'error',
            message: res.data.err_info,
            duration: 1000,
          })
        }
      })
    },
    /**
     * @param {string | number} val 变化结果值
     * @param {object} obj 需要处理的对象
     * @param {string} key 需要处理的对象的key
     * @param {boolean} isMinus 是否是负数 true为负数
     */
    handleInputVal(val, obj, key, isMinus) {
      const Reg = isMinus
        ? /(^\-?)\D*(\d{0,2})\d*(\.?)(\d{0,2})\d*/
        : /\D*(\d{0,2})\d*(\.?)(\d{0,2})\d*/
      const repStr = isMinus ? '$1$2$3$4' : '$1$2$3'
      obj[key] = regReplaceGlassEquipHandleInputVal(val, Reg, repStr)
    },
    changeObjValType(obj) {
      let o = { ...obj }
      Object.keys(o).forEach((key) => {
        if (!Number.isNaN(+o[key])) {
          o[key] = +o[key]
        }
      })
      return o
    },
  },
  created() {
    this.$store.commit('equipSetting/changeSavaButtonStatus', true)
  },
}
</script>

<style lang="less" scoped>
.container {
  padding: 0 24px 24px 24px;
  .group {
    // .head_title {
    // }
    .group_context {
      .col {
        padding-top: 24px;
        // .col_text {
        // }
        .el-select {
          width: 224px;
          /deep/.el-input {
            width: 100%;
          }
        }
        .el-input {
          width: 80px;
        }
      }
    }
  }
}
</style>
